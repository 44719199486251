import React from "react"
import SeoData from "@content/seo/company.json"
import Seo from "@components/seo"

// Import image
import ogpimage from '@images/ogp.png'; // 消すと絶対パス指定のstaticが使用できないので残しておく

// 固定ページSEO情報生成
export function SeoContents ({ Path:pathName }) {
  
  // ogpUrl設定
  let ogpUrl;
  if ( pathName === 'index' ) { // トップページ
    ogpUrl = process.env.ORIGINURL;
  } else { // その他ページ
    ogpUrl = process.env.ORIGINURL + "/" + pathName;
  }

  // company.jsonに設定した情報を取得
  const title = SeoData[ pathName + "_title" ];
  const description = SeoData[ pathName + "_description" ];
  
  // 共通設定
  const ogpSiteName = 'オウンドメディア運営・WEB集客&マーケティング会社';
  const ogpImage = process.env.ORIGINURL + ogpimage;
  const ogpImageWidth = '1200';
  const ogpImageHeight = '630';
  const ogpImageType = 'image/png';

  const seoTag =
  <Seo
    title={title}
    description={description}
    ogpUrl={ogpUrl}
    ogpSiteName={ogpSiteName}
    ogpImage={ogpImage}
    ogpImageWidth={ogpImageWidth}
    ogpImageHeight={ogpImageHeight}
    ogpImageType={ogpImageType}
  />
  return seoTag
}