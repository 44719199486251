import React, { useState } from "react"

import "@fontsource/inter"
import "@fontsource/noto-sans-jp"
import Logo from "@images/logo_head.svg"

export default function Header() {
  const indexUrl = '/';
  const cont1Url = '/business/';
  const cont2Url = '/outline/';
  const cont3Url = '/recruit/';
  const cont4Url = '/contact/';
 
  // // クラス付与
  const [active, setActive] = useState(false);
  const classToggle = () => {
  setActive(!active)
  }

  // // 追加クラス名 animation ※空白スペース必須
  const falseCname1 = " opacity-0 duration-0";
  const trueCname1 = " opacity-1 duration-500 h-[1000px]";
  const falseCname2 = " opacity-0 bg-none" ;
  const trueCname2 = " opacity-[0.8] bg-gray-800" ;
  const falseCname3 = " opacity-0 scale-y-0" ;
  const trueCname3 = " opacity-100 scale-y-100" ;
  const falseCname4 = " opacity-0 duration-150" ;
  const trueCname4 = " opacity-100 duration-300" ;
  const falseCname5 = "";
  const trueCname5 = " hidden";
  const falseCname6 = " hidden" ;
  const trueCname6 = "" ;

  return(
  <>
    <header>
      <div className="relative flex flex-wrap xl:justify-start xl:flex-nowrap z-50 w-full bg-white text-sm py-4 dark:bg-gray-800">
        <nav className="max-w-[192rem] w-full mx-auto px-4 xl:flex xl:items-center xl:justify-between" aria-label="Global">
          <div className="flex items-center justify-between">
            <a className="logo_head flex-none text-xl font-semibold dark:text-white" href={indexUrl} aria-label="ホームへ戻る" ><img src={ Logo } alt="" width="150" height="21"></img></a>
            
            {/* mbメニューボタン */}

            {/*
            <div className="xl:hidden">
              <button onClick={classToggle} type="button" class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800" data-hs-collapse="#navbar-collapse-slide-up" aria-controls="navbar-collapse-slide-up" aria-label="Toggle navigation">
                <svg className= {"w-4 h-4" + (active === true ? trueCname5 : falseCname5)} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
                <svg className= {"w-4 h-4" + (active === true ? trueCname6 : falseCname6)} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </div> 
          */}
          </div>
{/*
          <div className="hidden xl:block my-0 mx-5">
            <div class="hidden basis-full grow sm:block">
              <div className="text-[12px] flex flex-col gap-[30px] mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0">
                <a href={indexUrl} className="group relative my-0 mx-1">
                  <button id="hs-dropdown-hover-event" type="button" class="group-hover:text-gray-400 w-full text-gray-900 font-medium mb-2 xl:mb-0 z-10">
                    ホーム
                  </button>
                </a>

                <a href={cont1Url} className="group relative my-0 mx-1">
                  <button id="hs-dropdown-hover-event" type="button" class="group-hover:text-gray-400 w-full text-gray-900 font-medium mb-2 xl:mb-0 z-10">
                  事業内容
                  </button>
                </a>

                <a href={cont2Url} className="group relative my-0 mx-1">
                  <button id="hs-dropdown-hover-event" type="button" class="group-hover:text-gray-400 w-full text-gray-900 font-medium mb-2 xl:mb-0 z-10">
                  企業情報
                  </button>
                </a>

                <a href={cont3Url} className="group relative my-0 mx-1">
                  <button id="hs-dropdown-hover-event" type="button" class="group-hover:text-gray-400 w-full text-gray-900 font-medium mb-2 xl:mb-0 z-10">
                  採用情報
                  </button>
                </a>

                <a href={cont4Url} className="group relative my-0 mx-1">
                  <button id="hs-dropdown-hover-event" type="button" class="group-hover:text-gray-400 w-full text-gray-900 font-medium mb-2 xl:mb-0 z-10">
                  お問い合せ
                  </button>
                </a>

              </div>
            </div>
  </div> */}

          {/* mb用コンテンツ */}
         {/* <div className="xl:hidden">
            <div className= {"bgMenu absolute top-[70px] left-0 w-full" + (active === true ? trueCname1 : falseCname1)}>
              <div className= {"transform h-full w-full duration-500" + (active === true ? trueCname2 : falseCname2)}></div>
            </div>
            <div className= {"transform duration-300" + (active === true ? trueCname3 : falseCname3)}>
              <div className= {"Menu transform top-[35px] absolute w-full z-10" + (active === true ? trueCname4 : falseCname4)}>
                <div id="navbar-collapse-slide-up" class="basis-full grow sm:block">
                  <div class="accordion xl:hidden" id="accordionExample">
                    <div className="accordion-item bg-white border border-gray-200">
                      <div className="accordion-header mb-0 p-3 border-b" id="heading">
                        <button>
                          <a href={indexUrl} className="test z-10">ホーム</a>
                        </button>
                      </div>
                      <div className="accordion-header mb-0 p-3 border-b" id="heading">
                        <button>
                          <a href={cont1Url} className="test z-10">事業内容</a>
                        </button>
                      </div>
                      <div className="accordion-header mb-0 p-3 border-b"  id="heading">
                        <button>
                          <a href={cont2Url} className="test z-10 ">企業情報</a>
                        </button>
                      </div>
                      <div className="accordion-header mb-0 p-3 border-b" id="heading">
                        <button>
                          <a href={cont3Url} className="test z-10 ">採用情報</a>
                        </button>
                      </div>
                      <div className="accordion-header mb-0 p-3" id="heading">
                        <button>
                          <a href={cont4Url} className="test z-10">問合せ</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>    
            </div>
          </div>*/}
        </nav>
      </div>    
    </header>
  </>
  )
}